/* eslint-disable no-nested-ternary */
import { UserInfo } from "@app/models/user-info";
import { inject } from "@app/modules";
import { flatten, removeKeys } from "@app/utils/common";
import { Subscription } from "@app/utils/subscription";
import validateSchema from "@app/utils/validate-schema";
import { unitCollmise } from "collmise";
import { omitSecondaryUsers } from "..";
import { createModelCollmise } from "../collmise-helpers";
import { UserType } from "../helper-schemas";
import { IRequest } from "../requests";
import {
	IAPUTChangeUserFullName,
	IAPUTChangeUserPassword,
} from "./helper-schemas";
import {
	AGETUserProfileInfoSchema,
	APUTChangeStudentGradeSchema,
	APUTSaveDetailedInfoSchema,
	IADELETEUser,
	IAGETMobileExistence,
	IAGETUserFullInfo,
	IAGETUserProfile,
	IAGETUserProfileInfo,
	IAGETUserShortInfo,
	IAGETUserShortInfoByMobile,
	IAGETUsersShortInfo,
	IAGETUsersShortInfoByMobiles,
	IAPOSTVerifyCode,
	IAPUTChangeStudentGrade,
	IAPUTChangeUserMobile,
	IAPUTChangeUserType,
	IAPUTHasAgreedOnTerms,
	IAPUTSaveDetailedInfo,
	IAPUTUserMobileValidation,
	IAPUTVerifyMobileCode,
	IRGETMobileExistence,
	IRGETMyInfo,
	IRGETMyInfoAsMainAdmin,
	IRGETMyInfoAsStudent,
	IRGETMyInfoAsTeacher,
	IRGETMyUsersInfo,
	IRGETProfileInfo,
	IRGETProfileInfoAsAdmin,
	IRGETProfileInfoAsStudent,
	IRGETProfileInfoAsTeacher,
	IRGETUserFullInfo,
	IRGETUserShortInfo,
	IRGETUserShortInfoByMobile,
	IRGETUsersShortInfo,
	IRGETUsersShortInfoByMobiles,
	IRPOSTVerifyCode,
	IRPUTSaveDetailedInfo,
	RGETMyInfoAsMainAdminSchema,
	RGETMyInfoAsStudentSchema,
	RGETMyInfoAsTeacherSchema,
	RGETMyUsersInfoSchema,
	RGETUserShortInfoByMobileSchema,
	RGETUserShortInfoSchema,
	RGETUsersShortInfoByMobilesSchema,
	RGETUsersShortInfoSchema,
} from "./validators";

export class UsersController {
	[x: string]: any;
	private readonly Request: IRequest;

	private _ClassroomModel = inject("ClassroomModel");
	private _CourseInfoModel = inject("CourseInfoModel");
	private _StudentInfoModel = inject("StudentInfoModel");
	private _UserInfoModel = inject("UserInfoModel");
	private _GroupModel = inject("GroupModel");
	private assertAndGetClassroomsUser = inject("assertAndGetClassroomsUser");
	private lastUserInfoLoadTimes: Record<number, Date | undefined> = {};
	private readonly classroomsLoadThresholdTime = 5 * 60 * 1000;

	myInfoSubscription = new Subscription<IRGETMyInfo>();

	constructor(request: IRequest) {
		this.Request = request;
	}

	getChatToken = (): Promise<string> =>
		this.Request.send("GET", "/api/users/chat-token");

	async getProfileInfo(
		type: UserType.teacher
	): Promise<IRGETProfileInfoAsTeacher>;
	async getProfileInfo(
		type: UserType.student
	): Promise<IRGETProfileInfoAsStudent>;
	async getProfileInfo(
		type: UserType.mainAdmin
	): Promise<IRGETProfileInfoAsAdmin>;
	async getProfileInfo(
		type: UserType.teacher | UserType.student | UserType.mainAdmin
	): Promise<IRGETProfileInfo> {
		return this.Request.send("GET", "/api/users/profile-info");
	}

	myInfoCollmise = unitCollmise({
		responseCacheTimeoutMS: 1000,
	});

	async getMyInfo(
		type: UserType.teacher,
		loadFresh?: boolean
	): Promise<IRGETMyInfoAsTeacher>;
	async getMyInfo(
		type: UserType.student,
		loadFresh?: boolean
	): Promise<IRGETMyInfoAsStudent>;
	async getMyInfo(
		type: UserType.mainAdmin,
		loadFresh?: boolean
	): Promise<IRGETMyInfoAsMainAdmin>;
	async getMyInfo(
		type: UserType.teacher | UserType.student | UserType.mainAdmin,
		loadFresh?: boolean
	): Promise<IRGETMyInfo> {
		const user = this.assertAndGetClassroomsUser();
		if (!loadFresh && type === UserType.teacher) {
			const data = this.getMyInfoAsTeacherSync();
			if (data) return data;
		}
		this.myInfoHasBeenRequestedInThisSession = true;
		return this.myInfoCollmise.fresh(loadFresh).requestAs(() =>
			this.Request.send("GET", "/api/users/info", undefined, null, {
				responseSchema:
					type === UserType.teacher
						? RGETMyInfoAsTeacherSchema
						: type === UserType.student
						? RGETMyInfoAsStudentSchema
						: type === UserType.mainAdmin
						? RGETMyInfoAsMainAdminSchema
						: undefined,
			}).then((data: IRGETMyInfo) => {
				if (type === UserType.teacher && !user.isParent()) {
					this.handleTeacherInfoLoading(data as IRGETMyInfoAsTeacher);
				} else if (type === UserType.student && !user.isParent()) {
					this.handleStudentInfoLoading(data as IRGETMyInfoAsStudent);
				} else if (type === UserType.mainAdmin && !user.isParent()) {
					this.handleMainAdminInfoLoading(
						data as IRGETMyInfoAsMainAdmin
					);
				}
				this.lastUserInfoLoadTimes[user.id] = new Date();
				this.myInfoSubscription.broadcast(data);
				return data;
			})
		);
	}

	///
	async getChildInfo(childId: number): Promise<IRGETMyInfoAsStudent> {
		return this.Request.send(
			"GET",
			"/api/users/child-info",
			{ childId },
			null,
			{
				responseSchema: RGETMyInfoAsStudentSchema,
			}
		).then((data: IRGETMyInfoAsStudent) => {
			this.handleStudentInfoLoading(data);
			return data;
		});
	}

	///
	getMyStudents = async (loadFresh?: boolean): Promise<IRGETMyUsersInfo> => {
		if (!loadFresh) {
			const data = this.getMyInfoAsTeacherSync();
			if (data) {
				return data.users;
			}
		}
		return this.Request.send(
			"GET",
			"/api/users/my-users-info",
			undefined,
			undefined,
			{
				responseSchema: RGETMyUsersInfoSchema,
			}
		).then((data: IRGETMyUsersInfo) => {
			this._UserInfoModel.loadManySync(data);
			this._UserInfoModel.meta.updateLoadTime();
			return data;
		});
	};

	private userShortInfoCollmise = createModelCollmise({
		model: this._UserInfoModel,
		name: "UserShortInfo",
		idKey: "id",
		findCachedData: userId => this._UserInfoModel.findByUserIdSync(userId),
		getMany: userIds => {
			console.log("userIdsuserIdsuserIds", userIds);
			return this.Request.send<IRGETUsersShortInfo, IAGETUsersShortInfo>(
				"POST",
				"/api/users/get-many-by-ids",
				{ userIds },
				{ stripChildToken: true } as any,
				{
					responseSchema: RGETUsersShortInfoSchema,
				}
			);
		},
	});

	getUserShortInfo = async (
		args: IAGETUserShortInfo,
		loadFresh?: boolean
	): Promise<IRGETUserShortInfo> => {
		return this.userShortInfoCollmise
			.on(args.userId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send<IRGETUserShortInfo>(
					"GET",
					"/api/users/:userId",
					args,
					undefined,
					{ responseSchema: RGETUserShortInfoSchema }
				)
			);
	};

	getUsersShortInfo = async (
		args: IAGETUsersShortInfo,
		loadFresh?: boolean
	): Promise<UserInfo[]> => {
		return this.userShortInfoCollmise.collectors
			.many(args.userIds)
			.fresh(loadFresh)
			.request();
	};

	getManyUsersByMobileOrId = async (
		args: IAGETUsersShortInfoByMobiles
	): Promise<IRGETUsersShortInfoByMobiles> => {
		return this.Request.send(
			"POST",
			"/api/users/get-many-by-mobiles",
			args,
			undefined,
			{
				responseSchema: RGETUsersShortInfoByMobilesSchema,
			}
		).then((data: IRGETUsersShortInfoByMobiles) => {
			this._UserInfoModel.loadManySync(data);
			return data;
		});
	};

	getUserByMobileOrId = async (
		args: IAGETUserShortInfoByMobile
	): Promise<IRGETUserShortInfoByMobile> => {
		return this.Request.send(
			"GET",
			"/api/users/get-by-mobile",
			args,
			undefined,
			{
				responseSchema: RGETUserShortInfoByMobileSchema,
			}
		).then((data: IRGETUserShortInfoByMobile) => {
			this._UserInfoModel.loadOneSync(data);
			return data;
		});
	};

	saveDetailedInfo = async (
		args: IAPUTSaveDetailedInfo
	): Promise<IRPUTSaveDetailedInfo> => {
		return this.Request.send("PUT", "/api/users/", args, undefined, {
			requestSchema: APUTSaveDetailedInfoSchema,
		}).then((data: IRPUTSaveDetailedInfo) => {
			if (data) {
				const studentData: IRGETMyInfoAsStudent = validateSchema(
					data,
					RGETMyInfoAsStudentSchema
				);
				this.handleStudentInfoLoading(studentData);
			}
		});
	};

	private handleStudentInfoLoading = (data: IRGETMyInfoAsStudent) => {
		this._ClassroomModel.loadManySync(data.classrooms);
		this._ClassroomModel.meta.updateLoadTime();

		this._StudentInfoModel.loadOneSync(
			removeKeys(data, "classrooms", "courses")
		);
		this._CourseInfoModel.loadManySync(data.courses);
	};

	private handleTeacherInfoLoading = (data: IRGETMyInfoAsTeacher) => {
		this._UserInfoModel.loadManySync(data.users);
		this._UserInfoModel.meta.updateLoadTime();

		this._ClassroomModel.loadManySync(data.classrooms);
		this._ClassroomModel.meta.updateLoadTime();

		this._GroupModel.loadManySync(data.groups);
		this._GroupModel.meta.updateLoadTime();
	};

	getMyInfoAsTeacherSync = (): IRGETMyInfoAsTeacher | null => {
		const user = this.assertAndGetClassroomsUser();
		if (!user.isTeacher() && !user.isHeadmaster()) {
			throw new Error("user must be teacher");
		}
		const { lastFullLoadTime } = this._ClassroomModel.meta.data;
		const isUpToDate =
			lastFullLoadTime &&
			Date.now() - lastFullLoadTime.getTime() <
				this.classroomsLoadThresholdTime;
		if (!isUpToDate) {
			return null;
		}

		const groupIds = user.getOwnGroups();
		const groups = this._GroupModel.findManyByIdsSync(groupIds);
		if (groups.length !== groupIds.length) {
			return null;
		}

		const classroomIds = user.getOwnClassrooms();
		const classrooms = this._ClassroomModel.findManyByIdsSync(classroomIds);
		if (classrooms.length !== classroomIds.length) {
			return null;
		}
		const students = flatten(
			classrooms.map(e => e.studentIds),
			"unique"
		);
		const userShortInfo = this._UserInfoModel.findManyByIdsSync(students);
		if (userShortInfo.length !== students.length) {
			return null;
		}
		return {
			classrooms,
			users: userShortInfo,
			groups,
		};
	};

	private handleMainAdminInfoLoading = (data: IRGETMyInfoAsMainAdmin) => {
		this._CourseInfoModel.loadManySync(data.coursesInfo);
		// TODO: wrtie course settings in courses
	};

	sendVerificationCode = (
		args: IAPOSTVerifyCode
	): Promise<IRPOSTVerifyCode> => {
		return this.Request.send(
			"POST",
			"/api/users/send-verification-code",
			args
		);
	};

	sendVerificationCodeForRegister = (
		args: IAPOSTVerifyCode
	): Promise<IRPOSTVerifyCode> => {
		return this.Request.send(
			"POST",
			"/api/users/send-registration-verification-code",
			args
		);
	};

	verifyMobileCode = (args: IAPUTVerifyMobileCode): Promise<void> =>
		this.Request.send("PUT", "/api/users/verify-mobile-code", args);

	verifyRegistrationCode = (args: IAPUTVerifyMobileCode): Promise<void> =>
		this.Request.send("PUT", "/api/users/verify-registration-code", args);

	changeUserMobileValidation = (
		args: IAPUTUserMobileValidation
	): Promise<void> =>
		this.Request.send("PUT", "/api/users/mobile-validation", args);

	sendTextAfterRequestGrant = () => {
		this.Request.send("POST", "/api/users/text-after-user-request-grant");
	};

	getMobileExistence = async (
		args: IAGETMobileExistence
	): Promise<IRGETMobileExistence> =>
		this.Request.send("GET", "/api/users/check-mobile-existence", args);

	updateHasAgreedOnTerms = async (args: IAPUTHasAgreedOnTerms) =>
		this.Request.send(
			"PUT",
			"/api/users/has-agreed-on-terms",
			args,
			omitSecondaryUsers()
		);

	changeUserPassword = async (args: IAPUTChangeUserPassword) => {
		return this.Request.send(
			"PUT",
			"/api/users/:userId/change-password",
			args
		);
	};

	changeUserFullName = async (args: IAPUTChangeUserFullName) => {
		return this.Request.send("PUT", "/api/users/:userId/change-name", args);
	};

	changeUserMobile = async (args: IAPUTChangeUserMobile) =>
		this.Request.send("PUT", "/api/users/:userId/change-mobile", args);

	changeStudentGrade = async (args: IAPUTChangeStudentGrade) =>
		this.Request.send(
			"PUT",
			"/api/users/:userId/change-grade",
			args,
			null,
			{
				requestSchema: APUTChangeStudentGradeSchema,
			}
		);

	changeUserType = async (args: IAPUTChangeUserType) =>
		this.Request.send("PUT", "/api/users/:userId/change-type", args);

	deleteUser = async (args: IADELETEUser) =>
		this.Request.send("DELETE", "/api/users/:userId", args);

	getUserFullInfo = async (
		args: IAGETUserFullInfo
	): Promise<IRGETUserFullInfo> =>
		this.Request.send("GET", "/api/users/full-info", args);

	getUserProfileInfo = async (
		args: IAGETUserProfileInfo
	): Promise<IAGETUserProfile[]> =>
		this.Request.send("GET", "/api/users/get-profile-info", args, null, {
			requestSchema: AGETUserProfileInfoSchema,
		});
}
