/* eslint-disable max-lines-per-function */
import {
	APOSTRegisterUserSchema,
	IAPOSTRegisterUser,
	IRPOSTLogin,
} from "@app/api/auth/validators";
import { UserType } from "@app/api/helper-schemas";
import { UserTestStatus } from "@app/api/users/helper-schemas";
import { isStudentStatusVisible } from "@app/consts";
import {
	getCurrentWebsite,
	SubWebsiteOrigin,
	WebsiteOrigin,
} from "@app/globals";
import { useHistory, useWindowSize } from "@app/hooks/front";
import {
	useBoolean,
	useErrors,
	useForceUpdate,
	useMiniCallback,
	useMountingInfo,
	useUnsafeDynamicRef,
} from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useCountryOptions } from "@app/hooks/langs";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { getJoiErrorItems } from "@app/utils/joi-errors";
import { getFormattedMessage } from "@app/utils/locale";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import LocationIcon from "@material-ui/icons/LocationOn";
import SchoolIcon from "@material-ui/icons/School";
import classNames from "classnames";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useSubWebsite } from "../../hooks/bc";
import { GeneralContext } from "../general-context";
import { inputStyles } from "../profile/missing-profile-popup";
import { UserWAssignments } from "../users/assignments/tv-school/helpers/utils";
import { CheckboxWithLabel, InputWithLabel } from "../widgets/input";
import {
	AuthInput,
	AuthSelect,
	Button,
	ErrorTextWithTriangle,
	MobileInput,
	NameInput,
	PasswordInput,
	SurnameInput,
} from "./input-forms";
import { ParentRegistrationSecondStep } from "./parent-registration-second-step";
import StudentRegisterStyles from "./styles/student-register-page.module.css";
import { getAuthErrors } from "./validations";
import genStyles from "../profiles/general_components/styles/general_styles.module.css";

const useRedirectionIfInitiallyAlreadyLogged = () => {
	const history = useHistory();
	const user = useClassroomsUser();
	const userRef = useUnsafeDynamicRef(user);
	useEffect(() => {
		if (userRef.current) {
			history.push("/");
		}
	}, [history, userRef]);
};

export const StudentRegistrationPage: React.FC<{}> = props => {
	useRedirectionIfInitiallyAlreadyLogged();
	return <StandardRegistrationPage userType={UserType.student} />;
};
export const TeacherRegistrationPage: React.FC<{}> = props => {
	useRedirectionIfInitiallyAlreadyLogged();
	return <StandardRegistrationPage userType={UserType.teacher} />;
};

export const StandardRegistrationPage: React.FC<{
	userType: UserType.student | UserType.teacher;
}> = props => {
	const history = useHistory();
	const onSuccessSwitch = useCallback(() => {
		history.push("/");
	}, [history]);

	return (
		<div>
			<div>
				<StudentRegisterFirstStep
					onSuccess={onSuccessSwitch}
					userType={props.userType}
				/>
			</div>
		</div>
	);
};

export interface IStudentRegisterFirstStepProps {
	onSuccess: (data: IRPOSTLogin) => void;
	userType: UserType.student | UserType.teacher | UserType.parent | null;
	componentName?: string;
	buttonName?: string;
	classNames?: {
		button?: string;
		input?: string;
		container?: string;
	};
	defaultCredentials?: {
		firstname: string;
		lastname: string;
		mobile: string | null;
		password: string;
		isChecked: boolean;
		isRulesChecked?: boolean;
		isStudentChecked?: boolean;
		mail: string | null;
		country: string | null;
		specialCode: string | null;
		registrationOrigin: string;
		childrenMobile?: { mobile: string }[];
	};
	isIconVissible?: boolean;
	defaultValue?: string;
	placeholder?: string;
	isUpperTextVissible?: boolean;
	isNameAndSurnameTogether?: boolean;
	inputClassname?: string;
	registrationButtonClassname?: string;
	isRegisterIllustrationVissible?: boolean;
	isCodeInputVissible?: boolean;
	isCountryInputInvissible?: boolean;
	hasInputPlaceholder?: boolean;
	mobileInputClassname?: string;
	nameInputClassname?: string;
	isSpecialCheckbox?: boolean;
	isClickedOnContinue?: boolean;
	setClickedOnContinue?: (val: boolean) => void;
	isToTest?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const StudentRegisterFirstStep: React.FC<IStudentRegisterFirstStepProps> = props => {
	const { value: isLoading, setValue: setIsLoading } = useBoolean(false);
	const [isTeacherCheckboxMarked, setTeacherCheckBoxMarked] = useState(false);
	const [isRulesCheckBoxMarked, setRulesCheckBoxMarked] = useState(false);
	const [isStudentCheckBoxMarked, setStudentCheckBoxMarked] = useState(false);
	const [childrenInfos, setChildrenInfos] = useState<
		{
			mobile: React.MutableRefObject<string>;
			id: React.MutableRefObject<number | undefined>;
		}[]
	>([
		{
			mobile: useRef(""),
			id: useRef(),
		},
	]);
	const countryOptions = useCountryOptions();
	const locale = useLocale();
	const currentWebsite = getCurrentWebsite();
	const isTvSchool = currentWebsite === WebsiteOrigin.tvSchool;
	const isRegisteredByEmail = locale !== "ka" && isTvSchool;
	const {
		value: isUserTypeErrorTextVissible,
		setTrue: setUserTypeErrorTextVissible,
		setFalse: hideUserTypeErrorText,
	} = useBoolean(false);
	const forceUpdate = useForceUpdate();
	const {
		data: { authMetaData },
	} = useContext(GeneralContext);
	const { value: testingUser, switchValue: handleTestSwitch } = useBoolean(
		false
	);

	const credentials = useRef(
		props.defaultCredentials || {
			firstname: "",
			lastname: "",
			password: "",
			childrenMobile: [{ mobile: "" }],
			mobile: !isRegisteredByEmail ? "" : null,
			mail: isRegisteredByEmail ? "" : null,
			specialCode: props.isCodeInputVissible ? "" : null,
			isChecked: false,
			isRulesChecked: isRegisteredByEmail ? false : null,
			isStudentChecked: isRegisteredByEmail ? false : null,
			country: countryOptions[0] ? countryOptions[0].value : null,
			registrationOrigin: getCurrentWebsite(),
		}
	);

	const mountinfInfo = useMountingInfo();

	const { errors, removeErrorMessage, setErrors } = useErrors<
		typeof credentials.current
	>(null);

	const onPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.password = e.target.value;
			removeErrorMessage("password");
		},
		[removeErrorMessage]
	);
	const onPhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setIsCodeConfirmed(false);
			setIsCodeSent(false);

			credentials.current.mobile = e.target.value.replace(/\s/g, "");
			removeErrorMessage("mobile");
		},
		[removeErrorMessage]
	);
	const onMailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.mail = e.target.value;
			removeErrorMessage("mail");
		},
		[removeErrorMessage]
	);

	const onNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.firstname = e.target.value;
			removeErrorMessage("firstname");
		},
		[removeErrorMessage]
	);

	const onCountryChange = useCallback(
		(sel: { value: string; label: string }) => {
			credentials.current.country = sel.value;
			removeErrorMessage("country");
			forceUpdate();
		},
		[removeErrorMessage, forceUpdate]
	);

	const onSurnameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.lastname = e.target.value;
			removeErrorMessage("lastname");
		},
		[removeErrorMessage]
	);

	const onCheckBoxChange = useCallback(
		(val: boolean) => {
			setTeacherCheckBoxMarked(val);
			credentials.current.isChecked = !credentials.current.isChecked;
			removeErrorMessage("isChecked");
		},
		[removeErrorMessage]
	);

	const onRulesCheckBoxChange = useCallback(
		(val: boolean) => {
			setRulesCheckBoxMarked(val);
			credentials.current.isRulesChecked = !credentials.current
				.isRulesChecked;
			removeErrorMessage("isRulesChecked");
		},
		[removeErrorMessage]
	);

	const onStudentCheckBoxChange = useCallback(
		(val: boolean) => {
			setStudentCheckBoxMarked(val);
			credentials.current.isStudentChecked = !credentials.current
				.isStudentChecked;
			removeErrorMessage("isStudentChecked");
		},
		[removeErrorMessage]
	);

	const onSpecialCodeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.specialCode = e.target.value;
			removeErrorMessage("specialCode");
		},
		[removeErrorMessage]
	);

	const language = useLocale();

	const { onSuccess } = props;
	const hasFoundErr = useRef(false);

	const getRegistrationArgs = useMiniCallback(
		(): IAPOSTRegisterUser => {
			const writtenAssignmentsInfo =
				authMetaData?.writtenAssignmentsInfo ||
				UserWAssignments.getAll();

			const childrenIds: number[] = [];
			childrenInfos.map(mob => {
				if (mob.id.current) {
					childrenIds.push(mob.id.current);
				}
			});

			return {
				firstname: credentials.current.firstname,
				lastname: credentials.current.lastname,
				mobile: credentials.current.mobile?.trim(),
				password: credentials.current.password,
				country: credentials.current.country,
				mail: credentials.current.mail,
				language: language,
				hasAgreedOnTerms: !isBritishCenter,
				registrationOrigin:
					credentials.current.registrationOrigin ||
					getCurrentWebsite(),
				authMetaData: {
					...authMetaData,
					touSpecialCode:
						credentials.current.specialCode ??
						authMetaData?.touSpecialCode,
					writtenAssignmentsInfo:
						writtenAssignmentsInfo &&
						writtenAssignmentsInfo.length > 0
							? writtenAssignmentsInfo
							: undefined,
					userStatus:
						testingUser && props.userType === UserType.student
							? UserTestStatus.TEST
							: undefined,
				},
				childrenIds: childrenIds,
			};
		}
	);

	const onRedirectToContinueRegistration = useMiniCallback(() => {
		setIsLoading(true);
		const AuthController = inject("AuthController");

		let hasFoundError = false;

		const onError = (e: any) => {
			hasFoundErr.current = true;

			if (!mountinfInfo.isMounted) return;
			const joiErrors = getJoiErrorItems(e);

			if (joiErrors) {
				type firstErrors = Omit<
					typeof credentials.current,
					"isChecked"
				>;
				type secondErrors = Omit<firstErrors, "isRulesChecked">;
				type thirdErrors = Omit<secondErrors, "childrenMobile">;

				type errors = Omit<thirdErrors, "isStudentChecked">;

				const errorMessages = getAuthErrors<errors>(joiErrors);
				setErrors(e => ({ ...e, ...errorMessages }));
			} else {
				hasFoundErr.current = true;

				if (e && e.response && e.response.data) {
					if (credentials.current.mobile) {
						if (e.response.data.mobile) {
							setErrors(errors => ({
								...errors,
								mobile:
									e.response.data.mobile.indexOf(
										"duplication"
									) > -1
										? getFormattedMessage(
												"alreadyRegisteredByMobile"
										  )
										: "",
							}));
						}
					}

					if (credentials.current.password) {
						if (e.response.data.password) {
							setErrors(errors => ({
								...errors,
								password:
									e.response.data.password === "duplication"
										? "გთხოვთ, შეარჩიოთ მოსწავლისგან განსხვავებული პაროლი"
										: "",
							}));
						}
					}

					if (e.response.data.specialCode) {
						setErrors(errors => ({
							...errors,
							specialCode:
								e.response.data.specialCode === "incorrect"
									? getFormattedMessage("incorrectVerifCode")
									: "",
						}));
					}
				}
			}
			setIsLoading(false);
		};

		const promise = AuthController.validateParentRegistration;

		const args = getRegistrationArgs();

		if (hasFoundError) {
			setIsLoading(false);
			hasFoundErr.current = true;
			const validatorResult = APOSTRegisterUserSchema.validate(args, {
				stripUnknown: true,
				abortEarly: false,
			});
			if (validatorResult.error) {
				onError(validatorResult.error);
			}
			return;
		}
		promise(args)
			.then(data => {
				if (!mountinfInfo.isMounted) return;

				hasFoundError = false;

				setIsLoading(false);
				if (props.setClickedOnContinue) {
					props.setClickedOnContinue!(true);
				}
			})
			.catch(onError);
	});

	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;

	const [isCodeConfirmed, setIsCodeConfirmed] = useState(false);
	const [isCodeSent, setIsCodeSent] = useState(false);
	const testStudent = useRef(isBritishCenter);

	const [isDisabled, setIsDisabled] = useState(false);
	const [timeLeft, setTimeLeft] = useState(0);

	const showVerification = !isBritishCenter && !isCodeConfirmed;
	const showVerifyInput = !isBritishCenter && isCodeSent && !isCodeConfirmed;

	const showRegistrationButton = isBritishCenter || isCodeConfirmed;

	const {
		value: izLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const sendCodeRequest = useCallback(() => {
		startLoading();
		const UsersController = inject("UsersController");
		UsersController.sendVerificationCodeForRegister({
			mobile: credentials.current.mobile || undefined,
		})
			.then(() => {
				setIsCodeSent(true);
				stopLoading();
			})

			.catch(e => {
				alert("დაფიქსირდა შეცდომა");
				console.log(e);
				setIsDisabled(false);
				setIsCodeSent(false);
				stopLoading();
			});

		setIsDisabled(true);
		setTimeLeft(90);

		const interval = setInterval(() => {
			setTimeLeft(prev => {
				if (prev <= 1) {
					clearInterval(interval);
					setIsDisabled(false);
					setIsCodeSent(false);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
	}, [startLoading, stopLoading]);

	const [code, setCode] = useState("");

	const checkCode = useCallback(() => {
		startLoading();
		const UsersController = inject("UsersController");

		UsersController.verifyRegistrationCode({
			code: code,
			mobile: credentials.current.mobile!,
		})
			.then(() => {
				setIsCodeConfirmed(true);
				setTimeLeft(0);
				stopLoading();
			})
			.catch(error => {
				alert("დაფიქსირდა შეცდომა");
				console.log(error);
				setIsDisabled(false);
				setIsCodeSent(false);
				setTimeLeft(0);
				stopLoading();
			});
	}, [code, startLoading, stopLoading]);

	const handleRegistration = useMiniCallback(() => {
		setIsLoading(true);
		const AuthController = inject("AuthController");

		let hasFoundError = false;
		const userTypeStr =
			props.userType === UserType.student
				? "student"
				: props.userType === UserType.teacher
				? "teacher"
				: props.userType === UserType.parent
				? "parent"
				: props.userType === UserType.school
				? "school"
				: undefined;

		testStudent.current = userTypeStr === "student" && isBritishCenter;
		if (!userTypeStr) {
			hasFoundError = true;
			setUserTypeErrorTextVissible();
		} else {
			hideUserTypeErrorText();
		}
		if (
			props.userType === UserType.teacher &&
			!credentials.current.isChecked
		) {
			hasFoundError = true;
			setErrors(e => ({
				...e,
				isChecked: getFormattedMessage(
					"validations.teacherHasToBeChecked"
				),
			}));
		}

		if (isRegisteredByEmail && !credentials.current.isRulesChecked) {
			hasFoundError = true;
			setErrors(e => ({
				...e,
				isRulesChecked: getFormattedMessage("validations.emptyTerms"),
			}));
		}

		if (
			isRegisteredByEmail &&
			props.userType === UserType.student &&
			!credentials.current.isStudentChecked
		) {
			hasFoundError = true;
			setErrors(e => ({
				...e,
				isStudentChecked: getFormattedMessage(
					"validations.studentEmptyCheckBox"
				),
			}));
		}

		const onError = (e: any) => {
			if (!mountinfInfo.isMounted) return;
			const joiErrors = getJoiErrorItems(e);
			if (joiErrors) {
				type firstErrors = Omit<
					typeof credentials.current,
					"isChecked"
				>;
				type secondErrors = Omit<firstErrors, "isRulesChecked">;
				type thirdErrors = Omit<secondErrors, "childrenMobile">;

				type errors = Omit<thirdErrors, "isStudentChecked">;

				const errorMessages = getAuthErrors<errors>(joiErrors);
				setErrors(e => ({ ...e, ...errorMessages }));
			} else {
				if (e && e.response && e.response.data) {
					if (credentials.current.mobile) {
						if (e.response.data.mobile) {
							setErrors(errors => ({
								...errors,
								mobile:
									e.response.data.mobile.indexOf(
										"duplication"
									) > -1
										? getFormattedMessage(
												"alreadyRegisteredByMobile"
										  )
										: "",
							}));
						}
					}
					if (credentials.current.mail) {
						if (e.response.data.mail) {
							setErrors(errors => ({
								...errors,
								mail:
									e.response.data.mail === "duplication"
										? getFormattedMessage(
												"alreadyRegisteredByMail"
										  )
										: "",
							}));
						}
					}

					if (e.response.data.specialCode) {
						setErrors(errors => ({
							...errors,
							specialCode:
								e.response.data.specialCode === "incorrect"
									? getFormattedMessage("incorrectVerifCode")
									: "",
						}));
					}
				}
			}
			setIsLoading(false);
			triggerEvent(
				{
					category: "Button",
					action: "registration",
					label: "attempt",
				},
				{
					success: false,
					userType: userTypeStr,
				}
			);
		};

		const promise =
			props.userType === UserType.student
				? AuthController.registerStudent
				: props.userType === UserType.parent
				? AuthController.registerParent
				: AuthController.registerTeacher;

		const args = getRegistrationArgs();
		if (hasFoundError) {
			setIsLoading(false);
			const validatorResult = APOSTRegisterUserSchema.validate(args, {
				stripUnknown: true,
				abortEarly: false,
			});
			if (validatorResult.error) {
				onError(validatorResult.error);
			}
			return;
		}
		promise(args)
			.then(data => {
				if (
					!window.localStorage.getItem("invitedStudentPopup") &&
					authMetaData?.classroomEnrollmentCodes !== undefined
				) {
					window.localStorage.setItem("invitedStudentPopup", "1");
				}
				onSuccess(data);
				isTvSchool
					? triggerEvent(
							{
								category: "Button",
								action: "registration",
								label: "TVattempt",
							},
							{ success: true, userType: userTypeStr }
					  )
					: triggerEvent(
							{
								category: "Button",
								action: "registration",
								label: "Hubuattempt",
							},
							{ success: true, userType: userTypeStr }
					  );
				if (!mountinfInfo.isMounted) return;
				setIsLoading(false);
			})
			.catch(onError);
	});

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === "Enter") {
				if (props.setClickedOnContinue) {
					onRedirectToContinueRegistration();
				} else handleRegistration();
			}
		},
		[
			handleRegistration,
			props.setClickedOnContinue,
			onRedirectToContinueRegistration,
		]
	);
	const { width } = useWindowSize();
	const [agreed, setAgreed] = useState(false);
	return (
		<div
			className={
				props.isRegisterIllustrationVissible
					? StudentRegisterStyles.regiterPageInRegistrationPopup
					: classNames(
							StudentRegisterStyles.registerPage,
							props.classNames && props.classNames.container
					  )
			}
			tabIndex={0}
			onKeyDown={onKeyDown}
		>
			{props.isClickedOnContinue && childrenInfos ? (
				<div className={StudentRegisterStyles.parentNextStepContainer}>
					<div
						className={
							StudentRegisterStyles.parentNextStepTitleContainer
						}
					>
						მოსწავლის დასამატებლად, ჩაწერეთ ტელეფონის ნომერი,
						რომლითაც არის რეგისტრირებული
					</div>
					{childrenInfos.map((student, index) => (
						<ParentRegistrationSecondStep
							childInfo={student}
							placeholder={"მოსწავლის მობილური ნომერი"}
							inputClassname={StudentRegisterStyles.registerInput}
							childrenInfos={childrenInfos}
							setChildrenInfos={setChildrenInfos}
							key={index}
						/>
					))}
				</div>
			) : (
				<>
					{isUserTypeErrorTextVissible && !props.userType && (
						<ErrorTextWithTriangle
							errorText={getFormattedMessage(
								"validations.undefinedUserType"
							)}
						/>
					)}
					<div className={StudentRegisterStyles.register}>
						{props.componentName ||
							getFormattedMessage("registration")}
					</div>
					{!props.isCountryInputInvissible &&
						credentials.current.country &&
						countryOptions.length > 1 && (
							<div
								className={
									StudentRegisterStyles.registerInputAndUpperText
								}
							>
								<InputLabel
									label={getFormattedMessage(
										"inputs.country"
									)}
									isLabelHidden={!props.isUpperTextVissible}
								>
									{ref => (
										<AuthSelect
											errorText={errors && errors.country}
											// icon={<SchoolIcon />}
											isDisabled={isLoading}
											onChange={onCountryChange}
											options={countryOptions}
											value={
												countryOptions.find(
													s =>
														s.value ===
														credentials.current
															.country
												) || null
											}
											styles={inputStyles}
											placeholder={
												props.hasInputPlaceholder
													? getFormattedMessage(
															"inputs.country"
													  )
													: props.placeholder
											}
										/>
									)}
								</InputLabel>
							</div>
						)}
					<div
						className={
							props.isNameAndSurnameTogether ||
							props.isUpperTextVissible
								? width > 700
									? StudentRegisterStyles.fistAndLastnameInputContainers
									: StudentRegisterStyles.lastnameandFirstNameContainer
								: StudentRegisterStyles.lastnameandFirstNameContainer
						}
					>
						<div
							className={
								StudentRegisterStyles.registerInputAndUpperText
							}
							style={
								props.isUpperTextVissible
									? { marginRight: 10 }
									: { marginRight: 0 }
							}
						>
							<InputLabel
								label={getFormattedMessage("inputs.firstname")}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<NameInput
										onChange={onNameChange}
										isDisabled={isLoading}
										errorText={errors && errors.firstname}
										className={
											props.nameInputClassname
												? props.nameInputClassname
												: props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										defaultValue={
											props.defaultValue
												? props.defaultValue
												: credentials.current.firstname
										}
										isIconVissible={props.isIconVissible}
										placeholder={
											props.hasInputPlaceholder
												? getFormattedMessage(
														"inputs.firstname"
												  )
												: props.placeholder
										}
										inputRef={ref}
									/>
								)}
							</InputLabel>
						</div>

						<div
							className={
								StudentRegisterStyles.registerInputAndUpperText
							}
						>
							<InputLabel
								label={getFormattedMessage("inputs.lastname")}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<SurnameInput
										onChange={onSurnameChange}
										isDisabled={isLoading}
										errorText={errors && errors.lastname}
										className={
											props.nameInputClassname
												? props.nameInputClassname
												: props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										defaultValue={
											props.defaultValue
												? props.defaultValue
												: credentials.current.lastname
										}
										isIconVissible={props.isIconVissible}
										placeholder={
											props.hasInputPlaceholder
												? getFormattedMessage(
														"inputs.lastname"
												  )
												: props.placeholder
										}
										inputRef={ref}
									/>
								)}
							</InputLabel>
						</div>
					</div>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						<InputLabel
							label={getFormattedMessage("inputs.mobile")}
							isLabelHidden={!props.isUpperTextVissible}
						>
							{ref => (
								<div className={genStyles.personal_info_form}>
									<section
										className={
											genStyles.personal_info_form__input_wrapper
										}
									>
										<MobileInput
											onChange={onPhoneChange}
											isDisabled={isLoading}
											errorText={errors && errors.mobile}
											className={
												props.mobileInputClassname
													? props.mobileInputClassname
													: props.inputClassname
													? props.inputClassname
													: StudentRegisterStyles.input
											}
											defaultValue={
												props.defaultValue
													? props.defaultValue
													: credentials.current.mobile
													? credentials.current.mobile
													: ""
											}
											isIconVissible={
												props.isIconVissible
											}
											placeholder={
												props.hasInputPlaceholder
													? getFormattedMessage(
															"inputs.mobile"
													  )
													: props.placeholder
											}
											mainContainerWrapperClassname={
												StudentRegisterStyles.mainContainerWrapperClassname
											}
											inputRef={ref}
										/>{" "}
										{showVerification && (
											<React.Fragment>
												<p
													className={
														genStyles.personal_info_form__text
													}
												>
													მობილური ნომრის
													დასადასტურებლად, გთხოვთ
													გაიგზავნოთ კოდი ნომერზე
												</p>{" "}
												<div
													style={{
														display: "block",
														margin: "0 auto",
														marginTop: 10,
													}}
												>
													<Button
														isLoading={izLoading}
														onClick={() =>
															sendCodeRequest()
														}
														text={
															isDisabled
																? `კოდის ხელახლა მიღება ${timeLeft}წმ`
																: "კოდის მიღება"
														}
														className={
															genStyles.personal_info_form__code_btn
														}
														wrapperDivClassName={
															genStyles.fullWidth
														}
														disabled={isDisabled}
													/>
												</div>
											</React.Fragment>
										)}
									</section>
								</div>
							)}
						</InputLabel>
					</div>

					{showVerifyInput && (
						<div
							className={
								StudentRegisterStyles.registerInputAndUpperText
							}
						>
							<InputLabel
								label={getFormattedMessage(
									"inputs.verificationCode"
								)}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<div
										style={{
											display: "block",
											margin: "0 auto",
										}}
									>
										<div
											className={
												genStyles.personal_info_form
											}
										>
											<section
												className={
													genStyles.personal_info_form__input_wrapper
												}
											>
												{!isBritishCenter && (
													<React.Fragment>
														<div>
															<MobileInput
																onChange={e =>
																	setCode(
																		e.target.value.replace(
																			/\s/g,
																			""
																		)
																	)
																}
																isDisabled={
																	isLoading
																}
																errorText={
																	errors &&
																	errors.mobile
																}
																className={
																	props.mobileInputClassname
																		? props.mobileInputClassname
																		: props.inputClassname
																		? props.inputClassname
																		: StudentRegisterStyles.input
																}
																isIconVissible={
																	props.isIconVissible
																}
																placeholder={getFormattedMessage(
																	"inputs.verificationCode"
																)}
																mainContainerWrapperClassname={
																	StudentRegisterStyles.mainContainerWrapperClassname
																}
																inputRef={ref}
															/>{" "}
															<div
																style={{
																	display:
																		"block",
																	margin:
																		"0 auto",
																	marginTop: 10,
																}}
															>
																<Button
																	isLoading={
																		isLoading
																	}
																	onClick={
																		checkCode
																	}
																	className={
																		genStyles.personal_info_form__code_btn
																	}
																	text={
																		"დადასტურება"
																	}
																/>
															</div>
														</div>
													</React.Fragment>
												)}
											</section>
										</div>
									</div>
								)}
							</InputLabel>
						</div>
					)}
					{showRegistrationButton && (
						<div
							className={
								StudentRegisterStyles.registerInputAndUpperText
							}
						>
							<InputLabel
								label={getFormattedMessage("inputs.password")}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<PasswordInput
										onChange={onPasswordChange}
										isDisabled={isLoading}
										errorText={errors && errors.password}
										className={
											props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										defaultValue={
											props.defaultValue
												? props.defaultValue
												: credentials.current.password
										}
										isIconVissible={true}
										placeholder={
											props.hasInputPlaceholder
												? getFormattedMessage(
														"inputs.password"
												  )
												: props.placeholder
										}
										mainContainerWrapperClassname={
											StudentRegisterStyles.mainContainerWrapperClassname
										}
										inputRef={ref}
									/>
								)}
							</InputLabel>
						</div>
					)}
					{props.isCodeInputVissible && (
						<div
							className={
								StudentRegisterStyles.registerInputAndUpperText
							}
						>
							<InputLabel
								label={getFormattedMessage(
									"inputs.specialCode"
								)}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<AuthInput
										onChange={onSpecialCodeChange}
										isDisabled={isLoading}
										className={
											props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										isIconVissible={props.isIconVissible}
										placeholder={props.placeholder}
										mainContainerWrapperClassname={
											StudentRegisterStyles.mainContainerWrapperClassname
										}
										inputRef={ref}
										errorText={errors && errors.specialCode}
									/>
								)}
							</InputLabel>
						</div>
					)}
					{showRegistrationButton &&
						!isRegisteredByEmail &&
						props.userType !== UserType.parent && (
							<Terms setAgreed={setAgreed} />
						)}
					<div className={StudentRegisterStyles.checkboxContainers}>
						{props.userType === UserType.teacher && (
							<div
								className={
									StudentRegisterStyles.teacherCheckboxWithText
								}
							>
								{props.isSpecialCheckbox ? (
									<SpecialCheckBoxWithLabel
										onChange={onCheckBoxChange}
										value={isTeacherCheckboxMarked}
										label={getFormattedMessage(
											"teacherApproval"
										)}
									/>
								) : (
									<CheckboxWithLabel
										onChange={onCheckBoxChange}
										value={isTeacherCheckboxMarked}
										label={getFormattedMessage(
											"teacherApproval"
										)}
									/>
								)}

								{errors && errors.isChecked && (
									<ErrorTextWithTriangle
										errorText={getFormattedMessage(
											"validations.teacherHasToBeChecked"
										)}
									/>
								)}
							</div>
						)}

						{props.userType === UserType.student &&
							isBritishCenter &&
							isStudentStatusVisible && (
								<CheckboxWithLabel
									onChange={handleTestSwitch}
									value={testingUser}
									label={"საცდელი მოსწავლე"}
								/>
							)}
					</div>
				</>
			)}
			{showRegistrationButton && (
				<div
					className={
						StudentRegisterStyles.gobackAndSignupButtonWithTerms
					}
				>
					{props.userType === UserType.parent &&
						props.isClickedOnContinue && (
							<Terms setAgreed={setAgreed} />
						)}
					<div
						className={StudentRegisterStyles.gobackAndSignupButton}
					>
						{props.userType === UserType.parent &&
							props.isClickedOnContinue && (
								<div
									className={
										StudentRegisterStyles.gobackButton
									}
									onClick={
										props.setClickedOnContinue
											? () =>
													props.setClickedOnContinue!(
														false
													)
											: () => {}
									}
								>
									<ArrowLeftIcon style={{ fontSize: 29 }} />
									<FormattedMessage id="back" />
								</div>
							)}

						<Button
							onClick={
								props.userType === UserType.parent &&
								!props.isClickedOnContinue
									? onRedirectToContinueRegistration
									: handleRegistration
							}
							isLoading={isLoading}
							text={
								props.userType === UserType.parent &&
								!props.isClickedOnContinue
									? getFormattedMessage("continue")
									: props.buttonName ||
									  getFormattedMessage("signUp")
							}
							className={
								props.registrationButtonClassname
									? props.registrationButtonClassname
									: StudentRegisterStyles.actionButton
							}
							disabled={!isBritishCenter && !agreed}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const Terms: React.FC<{
	setAgreed: (value: ((prevState: boolean) => boolean) | boolean) => void;
}> = ({ setAgreed }) => {
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	return (
		<p className={StudentRegisterStyles.terms}>
			{!isBritishCenter && (
				<input
					style={{ marginTop: 5, marginRight: 5 }}
					type={"checkbox"}
					onChange={() => setAgreed(prev => !prev)}
				/>
			)}
			{getFormattedMessage("approveRules.first")}
			<Link
				to="/terms"
				target="_blank"
				className={StudentRegisterStyles.linkToTerms}
			>
				{getFormattedMessage("approveRules.second")}
			</Link>
		</p>
	);
};

const SpecialCheckBoxWithLabel: React.FC<{
	label: string;
	onChange: (value: boolean) => void;
	value: boolean;
}> = props => {
	return (
		<div
			onClick={() => props.onChange(!props.value)}
			className={StudentRegisterStyles.specialCheckBoxWithLabelContainer}
		>
			<div className={StudentRegisterStyles.specialCheckBoxContainer}>
				{props.value && (
					<div className={StudentRegisterStyles.specialCheckRect} />
				)}
			</div>
			{props.label}
		</div>
	);
};

export const SecondPageLoader: React.FC<{
	userType: UserType.student | UserType.teacher | UserType.parent;
}> = props => {
	const standardContentLoader = (
		<ContentLoader
			style={{ width: 280, height: 49 }}
			width={280}
			height={44}
		>
			<rect x="0" y="0" rx="5" ry="5" width="280" height="44" />
		</ContentLoader>
	);
	return (
		<>
			<div className={StudentRegisterStyles.inputContainer}>
				{standardContentLoader}
				<div className={StudentRegisterStyles.icon}>
					<LocationIcon />
				</div>
			</div>
			<div className={StudentRegisterStyles.inputContainer}>
				{standardContentLoader}
				<div className={StudentRegisterStyles.icon}>
					<SchoolIcon />
				</div>
			</div>
			{props.userType === UserType.student && (
				<div className={StudentRegisterStyles.inputContainer}>
					{standardContentLoader}
					<div className={StudentRegisterStyles.icon}>
						<InfoIcon />
					</div>
				</div>
			)}
			<ContentLoader
				style={{ width: 280, height: 49 }}
				width={280}
				height={44}
			>
				<rect x="0" y="0" rx="5" ry="5" width="280" height="44" />
			</ContentLoader>
		</>
	);
};

export const Steps: React.FC<{
	numOfSteps: number;
	currentStep: number;
}> = React.memo(props => {
	const arr = new Array(props.numOfSteps).fill(0);
	return (
		<div className={StudentRegisterStyles.steps}>
			{arr.map((e, i) => (
				<div
					key={i}
					className={
						i === props.currentStep
							? StudentRegisterStyles.completeStep
							: StudentRegisterStyles.incompleteStep
					}
				/>
			))}
		</div>
	);
});

export const InputLabel: React.FC<{
	isLabelHidden?: boolean;
	children: (
		inputRef: React.RefObject<HTMLInputElement | null>
	) => JSX.Element | null;
	label: string;
}> = ({ children, label, isLabelHidden }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const handleLabelClick = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	return (
		<>
			{!isLabelHidden && (
				<div
					className={StudentRegisterStyles.upperText}
					onClick={handleLabelClick}
				>
					{label}
				</div>
			)}
			{children(inputRef)}
		</>
	);
};
