import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
	UserTypes,
	UserType,
} from "../helper-schemas";
import { SchoolSchema, ISchool } from "./helper-schemas";
import { UserShortInfoSchema, IUserShortInfo } from "../users/helper-schemas";
import {
	ClassroomSchema,
	IClassroom,
	ISemester,
	SemesterSchema,
} from "../classrooms/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	UserWithProfilesSchema,
	IUserWithProfiles,
} from "../users/profiles/user-with-profiles/helper-schemas";

export const AGETSchoolSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
});
export interface IAGETSchool {
	id: number;
}

export const RGETSchoolSchema = SchoolSchema;
export type IRGETSchool = ISchool;

///

export const RGETSchoolsSchema = Joi.array().items(SchoolSchema);
export type IRGETSchools = ISchool[];

///

export const AGETSchoolsByCitySchema = Joi.object({
	cityId: Joi.number().required(),
});
export interface IAGETSchoolsByCity {
	cityId: number;
}

export const RGETSchoolsByCitySchema = Joi.array().items(SchoolSchema);
export type IRGETSchoolsByCity = ISchool[];

///

export const APOSTCreateSchoolShema = SchoolSchema.keys(insertStripKeys);
export type IAPOSTCreateSchool = ToInsertKeys<ISchool>;

export const RPOSTCreateSchoolSchema = SchoolSchema;
export type IRPOSTCreateSchool = ISchool;

///

export const APUTUpdateSchoolSchema = SchoolSchema.keys(updateStripKeys);
export type IAPUTUpdateSchool = ToUpdateKeys<ISchool>;

export const RPUTUpdateSchoolSchema = SchoolSchema;
export type IRPUTUpdateSchool = ISchool;

///

export const ADELETESchoolSchema = Joi.object({
	id: Joi.number().required(),
});

export interface IADELETESchool {
	id: number;
}

///

export const APOSTRegisterSchoolSchema = Joi.object({
	city: Joi.number().required(),
	school: Joi.number().required(),
	mail: Joi.string().required(),
	mobile: Joi.string().required(),
});

export interface IAPOSTRegisterSchool {
	city: number;
	school: number;
	mail: string;
	mobile: string;
}

///

export const APOSTImportClassroomSchema = Joi.object({
	city: Joi.number().required(),
	school: Joi.number().required(),
	country: Joi.string().required(),
	webOrigin: Joi.string().required(),
	language: Joi.string().required(),
	uniqueKey: Joi.string()
		.valid("mobile", "mail")
		.required(),
	options: Joi.object({
		ensureCourseUsers: Joi.boolean().required(),
		updateExistingUsersData: Joi.boolean().required(),
		removeOldStudentsFromClassroom: Joi.boolean().required(),
		updatePasswords: Joi.boolean().required(),
		strictCheckExistingUserNames: Joi.boolean().required(),
		doNotUploadClassrooms: Joi.boolean(),
		tempDisableChat: Joi.boolean(),
	}).required(),
});
export interface IAPOSTImportClassroom {
	city: number;
	school: number;
	country: string;
	language: string;
	webOrigin: string;
	uniqueKey: "mobile" | "mail";
	options: {
		ensureCourseUsers: boolean;
		updateExistingUsersData: boolean;
		removeOldStudentsFromClassroom: boolean;
		updatePasswords: boolean;
		strictCheckExistingUserNames: boolean;
		doNotUploadClassrooms?: boolean;
		tempDisableChat?: boolean;
	};
}

///

export const AGETManySchoolsByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.number())
		.required(),
});
export interface IAGETManySchoolsByIds {
	ids: number[];
}

export const RGETManySchoolsByIdsSchema = Joi.array().items(SchoolSchema);
export type IRGETManySchoolsByIds = ISchool[];

///

export const AGETExportedClassroomXLSXSchema = Joi.object({
	schoolId: Joi.number()
		.integer()
		.min(0)
		.required(),
	schoolLabelId: Joi.objectId()
		.empty("null")
		.allow(null)
		.default(null),
});
export interface IAGETExportedClassroomXLSX {
	schoolId: number;
	schoolLabelId: ObjectId | null;
}

///

export const GETExportedTimetableSchema = Joi.object({
	schoolId: Joi.number()
		.integer()
		.min(0)
		.required(),
});
export interface IGETExportedTimetable {
	schoolId: number;
}
///

export const APOSTSchoolHeadmasterSchema = Joi.object({
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	mobile: Joi.string().required(),
	password: Joi.string()
		.min(5)
		.required(),
	country: Joi.string().allow(null),
	language: Joi.string().allow(null),
	mail: Joi.string()
		.email({ tlds: { allow: false } })
		.allow(null),
	registrationOrigin: Joi.string()
		.allow(null)
		.required(),
	hasAgreedOnTerms: Joi.boolean(),

	city: Joi.number().allow(null),
	school: Joi.number()
		.integer()
		.required(),
});
export interface IAPOSTSchoolHeadmaster {
	firstname: string;
	lastname: string;
	mobile: string;
	password: string;
	country?: string | null;
	language?: string | null;
	registrationOrigin: string | null;
	mail?: string | null;
	hasAgreedOnTerms?: boolean;

	city?: number | null;
	school: number;
}

export interface IAPOSTUpdateBCStudent {
	userId: number;
	firstname: string | undefined | null;
	lastname: string | undefined | null;
	mobile: string | undefined | null;
	password: string | undefined | null;
}
///

export const AGETTeachersBySchoolsIdsSchema = Joi.object({
	schoolId: Joi.number().required(),
	schoolLabelId: Joi.objectId()
		.empty(["", "null"])
		.allow(null),
	includeArchivedToo: Joi.boolean(),
	includeOnlyArchived: Joi.boolean(),
});
export interface IAGETTeachersBySchoolsIds {
	schoolId: number;
	schoolLabelId: ObjectId | null;
	includeArchivedToo?: boolean;
	includeOnlyArchived?: boolean;
}

export const RGETTeachersBySchoolsIdsSchema = Joi.array().items(
	Joi.object({
		teacher: UserShortInfoSchema.required(),
		classrooms: Joi.array()
			.items(ClassroomSchema)
			.required(),
	})
);

export interface ITeacherWithClassrooms {
	teacher: IUserShortInfo;
	classrooms: IClassroom[];
}

export type IRGETTeachersBySchoolsIds = ITeacherWithClassrooms[];

///

export const AGETGroupGradebooksSchema = Joi.object({
	groupId: Joi.objectId().required(),
});
export interface IAGETGroupGradebooks {
	groupId: ObjectId;
}

///

export const AGETGroupYearGradebooksSchema = Joi.object({
	groupId: Joi.objectId().required(),
});
export interface IAGETGroupYearGradebooks {
	groupId: ObjectId;
	isPassable?: boolean;
}

///

export const AGETClassroomGradesSchema = Joi.object({
	gradebookId: Joi.objectId().required(),
	classroomId: Joi.objectId(),
});
export interface IAGETClassroomGrades {
	gradebookId: ObjectId;
	classroomId?: ObjectId;
	isPassable?: boolean;
}

///

export const AGETClassroomYearGradesSchema = AGETClassroomGradesSchema;
export type IAGETClassroomYearGrades = IAGETClassroomGrades;

///

export const AGETGroupGradebookStatsSchema = Joi.object({
	groupId: Joi.objectId().required(),
});
export interface IAGETGroupGradebookStats {
	groupId: ObjectId;
}

export const SemesterGradeSchema = Joi.alternatives(
	Joi.object({
		id: Joi.string().required(),
		subject: Joi.string().required(),
		grade: Joi.number(),
		absences: UnsignedIntegerSchema.required(),
		isPassed: Joi.boolean(),
		subjectId: Joi.string(),
	}),
	Joi.object({
		id: Joi.string().required(),
		subject: Joi.string(),
		grade: Joi.allow(null).required(),
		isPassed: Joi.boolean(),
		subjectId: Joi.string(),
	})
);
export type ISemesterGrade =
	| {
			id: string;
			subject: string;
			grade?: number;
			absences: number;
			isPassed?: boolean;
			subjectId?: string;
	  }
	| {
			id: string;
			subject: string;
			grade: null;
			absences?: undefined;
			isPassed?: boolean;
			subjectId?: string;
	  };

export const StudentSemesterDataSchema = Joi.object({
	userId: UnsignedIntegerSchema.required(),
	name: Joi.string().required(),
	semesterGradesBySubject: Joi.array()
		.items(SemesterGradeSchema)
		.required(),
});
export interface IStudentSemesterData {
	userId: number;
	name: string;
	semesterGradesBySubject: ISemesterGrade[];
}

export const RGETGroupGradebookStatsSchema = Joi.array().items(
	StudentSemesterDataSchema
);
export type IRGETGroupGradebookStats = IStudentSemesterData[];

///

export const AGETArchivedClassroomsByUserSchema = Joi.object({
	teacherId: Joi.number().required(),
	studentIds: Joi.number(),
	schoolId: Joi.number(),
	semester: SemesterSchema,
	isArchived: Joi.boolean().required(),
});
export interface IAGETArchivedClassroomsByUser {
	teacherId: number;
	studentIds?: number;
	schoolId?: number;
	semester?: ISemester;
	isArchived: boolean;
}

export const RGETArchivedClassroomsByUserSchema = Joi.array().items(
	ClassroomSchema
);
export type IRGETArchivedClassroomsByUser = IClassroom[];

///

///
export const AGETStudentGradebookStatsSchema = Joi.object({
	userId: UnsignedIntegerSchema.optional(),
	classroomId: Joi.objectId().optional(),
});
export interface IAGETStudentGradebookStats {
	userId?: number;
	classroomId?: ObjectId;
	gradebookId?: ObjectId;
}

export const RGETStudentGradebookStatsSchema = StudentSemesterDataSchema;
export type IRGETStudentGradebookStats = IStudentSemesterData;

export interface IAGETClassroomGradebookStats {
	classroomId: ObjectId;
}

export const RGETClassroomGradebookStatsSchema = Joi.array().items(
	StudentSemesterDataSchema
);
export type IRGETClassroomGradebookStats = IStudentSemesterData[];

///

export const AGETUsersOfSchoolSchema = Joi.object({
	schoolId: UnsignedIntegerSchema.required(),
	userTypes: Joi.array()
		.items(Joi.any().valid(...UserTypes))
		.optional(),
	schoolLabelId: Joi.objectId()
		.empty("null")
		.allow(null)
		.default(null),
});
export interface IAGETUsersOfSchool {
	schoolId: number;
	userTypes?: UserType[];
	schoolLabelId: ObjectId | null;
}

export const RGETUsersOfSchoolSchema = Joi.array().items(
	UserWithProfilesSchema
);
export type IRGETUsersOfSchool = IUserWithProfiles[];

export interface IAGETExportUsersToExcel {
	userType: UserType;
	schoolLabelId: ObjectId | null;
	schoolId: number | null;
}

export interface IAGETUsersOfSchoolRel {
	schoolId: number;
	userType: UserType;
}

///

export interface IAPUTSaveTeacherOfSchoolRel {
	mobile: string | null;
	schoolId: number;
	url: string | null;
	teacherId?: number | null;
}

///
export interface IAGETCheckUserOfSchoolBoolean {
	userId: number;
}
