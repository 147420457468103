import React from "react";
import termStyles from "./styles/terms.module.css";

export const GeoTermsContent = () => {
	return (
		<>
			<p className={termStyles.primary}>
				წინამდებარე დოკუმენტი განსაზღვრავს ინტერნეტ პორტალ tvschool.ge –
				ს (შემდგომში - საიტი) გამოყენების წესებსა და პირობებს (შემდგომში
				- წესები).
			</p>
			<p>
				საიტის მომხმარებელი (შემდგომში - მომხმარებელი) არის ნებისმიერი
				ფიზიკური პირი, რომელიც ნებისმიერი საშუალებით იყენებს საიტს.
			</p>
			<p>
				წესები წარმოადგენს იურიდიული ძალის მქონე ხელშეკრულებას ერთი
				მხრივ საიტის მფლობელ კომპანიას - შპს "კინგსი ჯგუფი“ (შემდგომში -
				tvschool.ge) და მეორე მხრივ საიტის მომხმარებელს შორის.
			</p>
			<p className={termStyles.primary}>
				საიტით სარგებლობით მომხმარებელი ადასტურებს, რომ გაეცნო საიტის
				წესებს, ასევე კონფიდენციალურობის განაცხადს და თანახმაა დაიცვას
				და შეასრულოს ისინი, უფლებამოსილია და თანახმაა იკისროს წესებით
				მისთვის განსაზღვრული ვალდებულებები და მიანიჭოს tvschool.ge - ს
				განსაზღვრული უფლებები.
			</p>
			<p>1. რეგისტრაცია</p>
			<div className={termStyles.termText}>
				<p>
					1.1. მომხმარებელი ვალდებულია საიტზე რეგისტრაციისას მიუთითოს
					სწორი მონაცემები.
				</p>
				<p>
					1.2. მომხმარებელი არ არის უფლებამოსილი სხვას მისცეს მისი
					ანგარიშით სარგებლობის უფლება.
				</p>
				<p>
					1.3. მომხმარებელი ვალდებულია არ გაამჟღავნოს და მესამე პირებს
					არ გადასცეს მის მიერ რეგისტრაციისას მითითებული ინფორმაცია,
					რომლითაც შესაძლებელი იქნება საიტზე ავტორიზაცია.
				</p>
				<p>
					1.4. მომხმარებლის პროფილში შენახული ინფორმაცია უნდა
					განახლდეს მომხმარებლის მიერ, მისი ცვლილების შემთხვევაში.
				</p>
				<p>
					1.5. მომხმარებელი ადასტურებს, რომ საიტის გამოყენებით არ
					ირღვევა ადგილობრივი, ეროვნული კანონმდებლობის ან/და
					საერთაშორისო სამართლის ნორმები, არ ილახება მესამე პირთა
					უფლებები.
				</p>
			</div>
			<p>2. დავალების დამატება</p>
			<div className={termStyles.termText}>
				<p>
					2.1. მასწავლებლად რეგისტრირებულ მომხმარებელს (შემდგომში -
					მასწავლებელი) საშუალება აქვს საიტზე დაამატოს მის მიერ
					შექმნილი დავალება.
				</p>
				<p>
					2.2. დავალების შინაარსზე სრული პასუხისმგებლობა ეკისრება
					მასწავლებელს.
				</p>
				<p>
					2.3. დავალების დამატებისას მასწავლებელი ადასტურებს და იძლევა
					გარანტიას, რომ:
				</p>
				<div className={termStyles.termText__sub}>
					<p>
						2.3.1. დავალების ატვირთვა არ დაარღვევს ადგილობრივი,
						ეროვნული თუ საერთაშორისო სამართლის მოქმედ ნორმებს;
					</p>
					<p>
						2.3.2. დავალების ატვირთვა არ დაარღვევს მესამე პირების
						უფლებებს (მათ შორის, საავტორო, სასაქონლო, საპატენტო ან
						ინტელექტუალური საკუთრების სფეროს მიკუთვნებულ ნებისმიერ
						სხვა უფლებას, პირად ქონებრივ ან არაქონებრივ უფლებებს,
						პერსონალურ მონაცემებთან დაკავშირებულ უფლებებს);
					</p>
					<p>
						2.3.3. დავალება არ შეიცავს ცილისწამებას, შეურაცხყოფას,
						მუქარას ან ძალადობისკენ მოწოდებას;
					</p>
				</div>
				2.4. დავალების დამატებით მასწავლებელი უფლებას აძლევს tvschool.ge
				- ს გამოიყენოს აღნიშნული დავალება ნებისმიერი, მათ შორის
				კომერციული მიზნით. tvschool.ge უფლებამოსილია გადასცეს აღნიშნული
				უფლება მესამე პირებს.
			</div>
			<p>3. საავტორო უფლებები</p>
			<div className={termStyles.termText}>
				<p>
					3.1. მასალა, რომელიც მომხმარებლისათვის ხელმისაწვდომია საიტზე
					წარმოადგენს tvschool.ge - ს ინტელექტუალურ საკუთრებას და
					მათზე ვრცელდება tvschool.ge - ს საკუთრების, საავტორო და
					საქართველოს კანონმდებლობით გათვალისწინებული ყველა ქონებრივი
					და არაქონებრივი უფლება.
				</p>
				<p>
					3.2. მომხმარებლის მიერ საიტზე არსებული მასალის გამოყენება
					დასაშვებია მხოლოდ საიტზე, არაკომერციული მიზნით.
				</p>
				<p>
					3.3. მომხმარებელს არ აქვს უფლება მოახდინოს საიტზე არსებული
					მასალის და მისი ნაწილის რეპროდუცირება, გამოქვეყნება,
					მოდიფიცირება, მესამე პირებისათვის გადაცემა ან ნებისმიერი
					სახით გამოყენება tvschool.ge - წინასწარი თანხმობის გარეშე.
				</p>
			</div>
			<p>4. პასუხისმგებლობა</p>
			<div className={termStyles.termText}>
				<p>
					4.1. tvschool.ge არ არის პასუხისმგებელი იმ ზიანზე, რომელიც
					შეიძლება საიტის ან მისი ცალკეული კომპონენტის შეფერხებით
					მიწოდებამ ან საიტის მუშაობის შეჩერებამ გამოიწვიოს.
				</p>
				<p>
					4.2. tvschool.ge არ არის პასუხიმგებელი იმ ზიანზე, რომელიც
					მომხმარებლის მიერ საიტის სარგებლობამ გამოიწვია.
				</p>
				<p>
					4.3. მომხმარებელი პასუხისმგებელია მის მიერ საიტის
					გამოყენებით წარმოშობილ ნებისმიერი ტიპის ზიანზე.
				</p>
				<p>
					4.4. tvschool.ge - ს უფლება აქვს ამ წესების დარღვევის გამო
					მომხმარებელს შეუზღუდოს საიტით სარგებლობის უფლება, მათ შორის
					გააუქმოს რეგისტრაცია.
				</p>
				<p>
					4.5. 4.4. მუხლით გათვალისწინებული შემთხვევის დადგომისას
					tvschool.ge არ კარგავს მის მიერ ამ წესებიდან გამომდინარე
					მიღებულ ნებისმიერ უფლებას.
				</p>
				<p>
					4.6. 4.4. მუხლით გათვალისწინებული შემთხვევის დადგომა არ
					ათავისუფლებს მომხმარებელს მისი ვალდებულებებისაგან, მათ შორის
					ზიანის ანაზღაურების ვალდებულებისაგან და არ აძლევს უფლებას
					მოითხოვოს ნებისმიერი ზიანის ანაზღაურება, რომელიც შესაძლოა
					tvschool.ge - ს მიერ 4.4. მუხლით გათვალისწინებული უფლების
					გამოყენებამ გამოიწვიოს.
				</p>
			</div>
			<p>5. კონფიდენციალურობა </p>
			<div className={termStyles.termText}>
				<p>
					5.1. საიტის გამოყენებით მომხმარებელი ადასტურებს, რომ იცნობს
					და ეთანხმება პერსონალური მონაცემების შეგროვების პრაქტიკას,
					რომელიც განსაზღვრულია კონფიდენციალურობის განაცხადით.
				</p>
				<p>
					5.2. კონფიდენციალურობის განაცხადი წესების შემადგენელი
					ნაწილია და მასზე სრულად ვრცელდება წესებით დადგენილი ყველა
					პირობა და დებულება.
				</p>
			</div>
			<p>6. ინდემნიტეტი</p>
			<div className={termStyles.termText}>
				<p>
					6.1. მომხმარებელმა უნდა აუნაზღაუროს tvschool.ge - ს
					ნებისმიერი ზიანი, რომელიც დადგება მომხმარებლის მიერ საიტის
					გამოყენების ან/და ამ წესების შეუსრულებლობის ან არაჯეროვანი
					შესრულების შედეგად.
				</p>
			</div>
			<p>7. წესების ცვლილება</p>
			<div className={termStyles.termText}>
				<p>
					7.1. tvschool.ge უფლებამოსილია ნებისმიერ დროს შეიტანოს
					ცვლილებები ან/და დამატებები წესებში და კონფიდენციალურობის
					განაცხადში მომხმარებლის წინასწარი შეტყობინების გარეშე.
				</p>
			</div>
			<p>8. დამატებითი პირობები</p>
			<div className={termStyles.termText}>
				<p>
					8.1. წესები განიმარტება საქართველოს კანონმდებლობით და
					ნებისმიერი დავა, რომელიც წარმოიშვება საიტის გამოყენებასთან
					დაკავშირებით უნდა განიხილოს თბილისის საქალაქო სასამართლომ.
				</p>
				<p>
					8.2. საიტი განკუთვნილია საქართველოს ტერიტორიაზე
					გამოყენებისათვის და მის ფარგლებს გარეთ საიტის გამოყენების
					შემთხვევაში თავად მომხმარებელია პასუხიმგებელი ადგილობრივი
					კანონმდებლობის დაცვაზე და მისი დარღვევით გამოწვეულ ნებისმიერ
					ზიანზე.
				</p>
				<p>
					8.3. წინამდებარე წესების ცალკეული დებულებების ბათილობა ან/და
					აღსრულების შეუძლებლობა გავლენას არ ახდენს წესების დანარჩენ
					დებულებებზე და ბათილის ან/და აღუსრულებელი წესი ან დებულება
					ჩანაცვლდება იმ აღსრულებადი წესით ან დებულებით, რომელიც
					ყველაზე ახლოს დგას აღუსრულებელი წესის ან დებულების მიზანთან.
				</p>
			</div>
		</>
	);
};
