import { School } from "@app/models/schools";
import { inject } from "@app/modules";
import { flatten } from "@app/utils/common";
import { IRequest } from "../requests";
import {
	IRGETSchools,
	RGETSchoolsSchema,
	IAGETSchoolsByCity,
	IRGETSchoolsByCity,
	RGETSchoolsByCitySchema,
	IAPOSTRegisterSchool,
	APOSTRegisterSchoolSchema,
	IRGETSchool,
	RGETSchoolSchema,
	IADELETESchool,
	IAPOSTCreateSchool,
	IAPUTUpdateSchool,
	IRPOSTCreateSchool,
	IRPUTUpdateSchool,
	RPUTUpdateSchoolSchema,
	RPOSTCreateSchoolSchema,
	IAGETManySchoolsByIds,
	IRGETManySchoolsByIds,
	IAGETExportedClassroomXLSX,
	IAPOSTSchoolHeadmaster,
	IAGETTeachersBySchoolsIds,
	IRGETTeachersBySchoolsIds,
	AGETTeachersBySchoolsIdsSchema,
	RGETTeachersBySchoolsIdsSchema,
	IAGETGroupGradebookStats,
	IRGETGroupGradebookStats,
	RGETGroupGradebookStatsSchema,
	IAGETGroupGradebooks,
	IAGETClassroomGrades,
	IAGETArchivedClassroomsByUser,
	IAGETStudentGradebookStats,
	IRGETStudentGradebookStats,
	RGETStudentGradebookStatsSchema,
	IAGETClassroomGradebookStats,
	IRGETClassroomGradebookStats,
	RGETClassroomGradebookStatsSchema,
	IGETExportedTimetable,
	RGETUsersOfSchoolSchema,
	IAGETUsersOfSchool,
	IRGETUsersOfSchool,
	AGETUsersOfSchoolSchema,
	IAGETExportUsersToExcel,
	IAGETGroupYearGradebooks,
	IAGETClassroomYearGrades,
	IAGETUsersOfSchoolRel,
	IAPUTSaveTeacherOfSchoolRel,
	IAGETCheckUserOfSchoolBoolean,
	IRGETArchivedClassroomsByUser, IAPOSTUpdateBCStudent,
} from "./validators";
import { IUserWithProfiles } from "../users/profiles/user-with-profiles/helper-schemas";
import { SchoolStructure } from "../../models/school-structure";

export class SchoolsController {
	private readonly Request: IRequest;

	private readonly _SchoolModel = inject("SchoolModel");
	private readonly _ClassroomModel = inject("ClassroomModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	getAll = async (): Promise<IRGETSchools> => {
		return this.Request.send("GET", "/api/schools", undefined, null, {
			responseSchema: RGETSchoolsSchema,
		});
	};

	getManyByIds = async (
		args: IAGETManySchoolsByIds,
		loadFresh?: boolean
	): Promise<School[]> => {
		if (!loadFresh) {
			const schools = this._SchoolModel.findManyByIdsSync(args.ids);
			if (schools.length === args.ids.length) {
				return schools;
			}
		}
		return this.Request.send(
			"POST",
			"/api/schools/get-by-ids",
			args,
			null,
			{
				responseSchema: RGETSchoolsSchema,
			}
		).then((schools: IRGETManySchoolsByIds) => {
			return this._SchoolModel.loadManySync(schools);
		});
	};

	getById = async (id: number, loadFresh?: boolean): Promise<School> => {
		if (!loadFresh) {
			const school = this._SchoolModel.findByIdSync(id);
			if (school) return school;
		}
		return this.Request.send("GET", "/api/schools/:id", { id }, null, {
			responseSchema: RGETSchoolSchema,
		}).then((school: IRGETSchool) => {
			return this._SchoolModel.loadOneSync(school);
		});
	};

	getByCity = async (
		args: IAGETSchoolsByCity
	): Promise<IRGETSchoolsByCity> => {
		return this.Request.send("GET", "/api/schools/by-city", args, null, {
			responseSchema: RGETSchoolsByCitySchema,
		});
	};

	getTeachersBySchoolId = async (
		args: IAGETTeachersBySchoolsIds
	): Promise<IRGETTeachersBySchoolsIds> => {
		return this.Request.send(
			"GET",
			"/api/schools/:schoolId/teachers",
			args,
			null,
			{
				requestSchema: AGETTeachersBySchoolsIdsSchema,
				responseSchema: RGETTeachersBySchoolsIdsSchema,
			}
		).then((data: IRGETTeachersBySchoolsIds) => {
			const classrooms = flatten(data.map(e => e.classrooms));
			this._ClassroomModel.loadManySync(classrooms);
			return data;
		});
	};

	async create(args: IAPOSTCreateSchool): Promise<School> {
		return this.Request.send("POST", "/api/schools", args, null, {
			responseSchema: RPOSTCreateSchoolSchema,
		}).then((school: IRPOSTCreateSchool) =>
			this._SchoolModel.loadOneSync(school)
		);
	}

	async update(args: IAPUTUpdateSchool): Promise<School> {
		return this.Request.send("PUT", "/api/schools/:id", args, null, {
			responseSchema: RPUTUpdateSchoolSchema,
		}).then((school: IRPUTUpdateSchool) =>
			this._SchoolModel.loadOneSync(school)
		);
	}

	async delete(args: IADELETESchool) {
		return this.Request.send("DELETE", "/api/schools/:id", args).then(
			() => {
				this._SchoolModel.deleteByIdSync(args.id);
			}
		);
	}

	async registerSchool(args: IAPOSTRegisterSchool) {
		return this.Request.send("POST", "/api/schools/register", args, null, {
			requestSchema: APOSTRegisterSchoolSchema,
		});
	}

	async uploadClassrooms(args: FormData): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/schools/import-classrooms",
			args
		);
	}

	async exportClassrooms(args: IAGETExportedClassroomXLSX): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/export-classrooms",
			args,
			{
				responseType: "blob",
			}
		);
	}

	addHeadmaster(args: IAPOSTSchoolHeadmaster): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/schools/:school/headmasters",
			args
		);
	}

	updateBCStudent(args: IAPOSTUpdateBCStudent): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/schools/update-bc-student",
			args
		);
	}

	exportGroupGradebooks(args: IAGETGroupGradebooks): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/groups/:groupId/excel-gradebooks",
			args,
			{
				responseType: "blob",
			}
		);
	}

	exportGroupYearGradebooks(args: IAGETGroupYearGradebooks): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/groups/:groupId/excel-year-gradebooks",
			args,
			{
				responseType: "blob",
			}
		);
	}

	exportClassroomGrades(args: IAGETClassroomGrades): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/gradebooks/:gradebookId",
			args,
			{
				responseType: "blob",
			}
		);
	}

	exportClassroomYearGrades(args: IAGETClassroomYearGrades): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/gradebooks/year/:gradebookId",
			args,
			{
				responseType: "blob",
			}
		);
	}

	getGroupGradebookStats(
		args: IAGETGroupGradebookStats
	): Promise<IRGETGroupGradebookStats> {
		return this.Request.send(
			"GET",
			"/api/schools/groups/:groupId/gradebook-stats",
			args,
			null,
			{
				responseSchema: RGETGroupGradebookStatsSchema,
			}
		);
	}

	getClassroomGradebookStats(
		args: IAGETClassroomGradebookStats
	): Promise<IRGETClassroomGradebookStats> {
		return this.Request.send(
			"GET",
			"/api/schools/classrooms/:classroomId/gradebook-stats",
			args,
			null,
			{
				responseSchema: RGETClassroomGradebookStatsSchema,
			}
		);
	}

	getArchivedClassroomsForTeacher(
		args: IAGETArchivedClassroomsByUser
	): Promise<IRGETArchivedClassroomsByUser> {
		return this.Request.send(
			"GET",
			"/api/schools/archive/get-archived-classrooms/teacher",
			args
		);
	}

	getStudentGradebookStats(
		args: IAGETStudentGradebookStats = {}
	): Promise<IRGETStudentGradebookStats> {
		return this.Request.send(
			"GET",
			"/api/schools/students/gradebook-stats",
			args,
			null,
			{
				responseSchema: RGETStudentGradebookStatsSchema,
			}
		);
	}

	exportSchedule(args: IGETExportedTimetable): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/export/schedule/export-schedule-to-excel",
			args,
			{
				responseType: "blob",
			}
		);
	}

	async getUsersOfSchool(
		args: IAGETUsersOfSchool
	): Promise<IRGETUsersOfSchool> {
		return this.Request.send(
			"GET",
			"/api/schools/:schoolId/users",
			args,
			null,
			{
				requestSchema: AGETUsersOfSchoolSchema,
				responseSchema: RGETUsersOfSchoolSchema,
			}
		);
	}
	async getUsersOfSchoolRel(
		args: IAGETUsersOfSchoolRel
	): Promise<IUserWithProfiles[]> {
		return this.Request.send(
			"GET",
			"/api/schools/get-teachers-school-rel",
			args,
			null,
			{
				responseSchema: RGETUsersOfSchoolSchema,
			}
		);
	}
	async checkUserOfSchoolRecord(
		args: IAGETCheckUserOfSchoolBoolean
	): Promise<{ exists: boolean }> {
		return this.Request.send(
			"GET",
			"/api/schools/check-user-of-school-rel",
			args,
			null
		);
	}
	async saveTeacherOfSchoolRel(args: IAPUTSaveTeacherOfSchoolRel) {
		return this.Request.send(
			"PUT",
			"/api/schools/save-teacher-school-rel",
			args,
			null
		);
	}
	exportList(args: IAGETExportUsersToExcel) {
		return this.Request.send(
			"GET",
			"/api/schools/users/export-users-to-excel",
			args,
			{
				responseType: "blob",
			}
		);
	}

	async getAllSchoolLabelIds(): Promise<SchoolStructure> {
		return this.Request.send("GET", "/api/schools/school-labels");
	}
}
