import React from "react";
import termStyles from "./styles/terms.module.css";
import { useLocale } from "@app/hooks/intl";
import { DutchConfidentiality } from "./dutch-confidentiality";
import { EngConfidentiality } from "./eng-confidentiality";

export const GeoConfidentiality = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.title}>
					კონფიდენციალურობის პოლიტიკა
				</h1>
				<p>
					შპს „კინგსი ჯგუფი“ (შემდგომში - ჩვენ) უზრუნველყოფს
					ვებგვერდის www.tvschool.ge (შემდეგში - საიტი) -
					მომხმარებლების კონფიდენციალურობის მაღალი ხარისხით დაცვას.
				</p>
				<p>
					საიტის გამოყენებით თქვენ ეთანხმებით წინამდებარე
					კონფიდენციალურობის განაცხადში აღწერილ პერსონალურ მონაცემთა
					დამუშავების პრაქტიკას (შემდგომში — განაცხადი).
				</p>
				<p>
					წინამდებარე განაცხადი წარმოადგენს ჩვენი საიტის გამოყენების
					წესების და პირობების ინტეგრალურ ნაწილს და მასზე სრულად
					ვრცელდება წესებისა და პირობების მოქმედება. ყველაფერი, რაც არ
					წარმოადგენს წინამდებარე განაცხადის რეგულირების საგანს,
					რეგულირდება და განიმარტება საიტის წესების და პირობების
					დებულებების შესაბამისად.
				</p>
				<p className={termStyles.secondary}>
					პერსონალური ინფორმაცია, რომელსაც ვამუშავებთ
				</p>
				<p className={termStyles.marginTB}>
					პერსონალური ინფორმაცია მუშავდება საიტის სერვისის გამართული
					მუშაობის, განვითარებისა და გაუმჯობესებისათვის, იმისათვის,
					რომ თქვენ შეძლოთ და გაგიადვილდეთ საიტის გამოყენება,
					პირდაპირი მარკეტინგის განხორციელებისთვის, მომსახურებისა და
					ვებგვერდის განვითარებისთვის. Tvschool - იღებს
					პასუხისმგებლობას მომხმარებლის მიერ გადმოცემული პერსონალური
					ინფორმაციის უსაფრთხოებაზე.
				</p>
				<p>რეგისტაციისას ჩვენ ვამუშავებთ შემდეგ ინფორმაციას:</p>
				<div className={termStyles.termText}>
					<p>თქვენი სახელი და გვარი</p>
					<p>თქვენი ტელეფონის ნომერი</p>
				</div>
				<p className={termStyles.marginTB}>
					საიტის გამოყენებისას ჩვენ ვამუშავებთ ინფორმაციას ე.წ.
					ლოგების სახით. საიტზე მომხმარებელთა სტუმრობისას, სერვერზე
					ფიქსირდება საიტზე შესვლის თარიღი და მეთოდი, ინტერნეტ
					პროტოკოლის მისამართი, რეფერალი და დათვალიერებული რესურსის
					მისამართი.
				</p>
				<p className={termStyles.secondary}>
					ანალიტიკური სერვისების გამოყენება
				</p>
				<p className={termStyles.marginTB}>
					საიტის ოპტიმიზაციის, ხარისხის გაუმჯობესებისა და ტექნიკური
					შეფასების მიზნით, ჩვენ ვიყენებთ სერვისებს: Google Analytics,
					Amplitude, Hotjar, Top.ge. აღნიშნული სერვისები იძლევა
					სტატისტიკურ მონაცემებსა(მაგალითად, რომელი
					ქვეყნიდან/ქალაქიდან განხორციელდა საიტზე წვდომა, საიტზე
					სტუმრობის ხანგრძლივობა, გამოყენებული ოპერაციული სისტემა) და
					ინფორმაციას მომხმარებლის განხორციელებული ქმედებების(ძიების
					შინაარსი, გაგზავნილი დავალებები და ა.შ.) შესახებ. აღნიშნული
					სერვისების კონფიდენციალურობის პოლიტიკა ხელმისაწვდომია
					საჯაროდ შემდეგ ბმულებზე:{" "}
					<a
						href="https://policies.google.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
						className={termStyles.linkToMail}
					>
						Google Analytics,
					</a>
					<a
						href="https://help.amplitude.com/hc/en-us/articles/206533238-Data-Security-Privacy"
						target="_blank"
						rel="noopener noreferrer"
						className={termStyles.linkToMail}
					>
						Amplitude,
					</a>
					<a
						href="https://www.hotjar.com/legal/policies/privacy/"
						target="_blank"
						rel="noopener noreferrer"
						className={termStyles.linkToMail}
					>
						Hotjar,
					</a>
					<a
						href="https://top.ge/legal"
						target="_blank"
						rel="noopener noreferrer"
						className={termStyles.linkToMail}
					>
						Top.ge.
					</a>
				</p>
				<p className={termStyles.marginTB}>
					ვებგვერდი მომხმარებელთან სწრაფი კომუნიკაციისთვის იყენებს
					Customer Chat Plugin-ს მხოლოდ იმ შემთხვევაში, თუ
					მომხმარებელი დაეთანხმება საკუთარი Facebook ანგარიშის
					გამოყენებით შეკითხვის გამოგზავნას. ამ გზით მოპოვებული
					ინფორმაციას ვებგვერდი იყენებს მხოლოდ მომხმარებლის სწრაფი
					მხარდაჭერისთვის და არ ინახავს მას შემდგომი დამუშავებისთვის.
					Customer Chat Plugin-ის კონფიდენციალურობის პოლიტიკა
					ხელმისაწვდომია საჯაროდ
					<a
						href="https://www.facebook.com/about/privacy"
						target="_blank"
						rel="noopener noreferrer"
						className={termStyles.linkToMail}
					>
						Facebook.com-ზე.
					</a>
				</p>
				<p className={termStyles.marginTB}>
					მზა ჩანაწერები“ (Cookies) ჩვენ საიტზე „მზა ჩანაწერები“
					გამოიყენება მომხმარებლისათვის საიტზე ნავიგაციის
					გამარტივების, ინფორმაციის სასურველი ფორმატით შეთავაზების და
					ძებნის პარამეტრების გაუმჯობესების მიზნით. „მზა ჩანაწერების“
					მეშვეობით შესაძლებელია დადგინდეს ვიზიტორის მიერ საიტზე
					გატარებული დროის ხანგრძლივობა, რომელი ქალაქიდან განხორციელდა
					წვდომა და რა ენაზე გაეცნო სტუმარი ინფორმაციას და ვებგვერდზე
					ტექნიკური ხასიათის პრობლემას ხომ არ ჰქონდა ადგილი.
				</p>
				<p className={termStyles.secondary}>
					რა მიზნით ვამუშავებთ თქვენს პერსონალურ ინფორმაციას
				</p>
				<p>
					პერსონალური ინფორმაცია მუშავდება საიტის სერვისის გამართული
					მუშაობის, განვითარებისა და გაუმჯობესებისათვის, იმისათვის,
					რომ თქვენ შეძლოთ და გაგიადვილდეთ საიტის გამოყენება,
					მარკეტინგული მიზნებისათვის, თქვენთან დასაკავშირებლად,
					სტატისტიკური მიზნებისათვის და იმის დასადგენად, თუ როგორ
					იყენებენ ჩვენს საიტს.
				</p>
				<p className={termStyles.secondary}>
					რა ვადით ვინახავთ თქვენს პერსონალურ მონაცემებს
				</p>
				<p>
					ჩვენ ვინახავთ თქვენს პერსონალურ ინფორმაციას მხოლოდ იმ ვადით,
					რაც საჭიროა ამ განაცხადში დასახელებული მიზნების მისაღწევად.
					თუ თქვენი მონაცემების შენახვა არ არის საჭირო მიზნების
					მისაღწევად ჩვენ შეიძლება წავშალოთ ის, ან შევცვალოთ ჩანაწერი
					ისე, რომ თქვენი იდენტიფიცირება არ იყოს შესაძლებელი.
				</p>
				<p className={termStyles.secondary}>
					თქვენი პერსონალური მონაცემების უსაფრთხოება
				</p>
				<p>
					ჩვენ დავნერგეთ ყველა გონივრული ტექნიკური და ორგანიზაციული
					უსაფრთხოების ზომა, იმისათვის, რომ საიტზე და სხვა
					საშუალებებით მოპოვებული თქვენი პერსონალური მონაცემები დაცული
					იყოს უნებართვო წვდომისგან, გამოყენებისგან, დაკარგვისგან ან
					განადგურებისგან. რეგისტრაციისას თქვენ ვალდებული ხართ
					შეარჩიოთ უსაფრთხო პაროლი და არ გადასცეთ იგი სხვას.
				</p>
				<p className={termStyles.secondary}>
					ვის ვუზიარებთ თქვენს პერსონალურ მონაცემებს
				</p>
				<p>
					"tvschool" უფლებამოსილია გასცეს თქვენი პერსონალური
					მონაცემები კანონმდებლობის მოთხოვნით ან/და სამართლებრივ
					დავებში საკუთარი ინტერესების დასაცავად, ან/და კომპანიის
					რეორგანიზაციის, შერწყმის ან გაყიდვის შედეგად; "tvschool"
					ასევე უფლებამოსილია დაამუშაოს თქვენი პერსონალური მონაცემები
					მესამე პირთა სასარგებლოდ, კერძოდ, გასცეს ან/და გაამჟღავნოს
					აღნიშნული მონაცემები მესამე პირთა მარკეტინგული, სარეკლამო,
					საინფორმაციო ან სხვა სახის კომერციული მიზნებისთვის.
				</p>
				<p className={termStyles.secondary}>
					თქვენი უფლებები ჩვენს მიერ შენახულ თქვენს პერსონალურ
					მონაცემებთან დაკავშირებით
				</p>
				<p>თქვენ შეგიძლიათ მოითხოვოთ:</p>
				<p>
					ჩვენთან შენახული თქვენი პერსონალური მონაცემების ასლის
					გადაცემა.
				</p>
				<p>თქვენს პერსონალურ მონაცემებში უზუსტობის აღმოფხვრა.</p>
				<p>
					თქვენი პერსონალური მონაცემების წაშლა, ამისთვის უნდა
					გადმოაგზავნოთ აღნიშნული მოთხოვნის წერილი, პერსონალური
					მონაცემების დამუშავების შეწყვეტის თაობაზე, თანდართული
					მონაცემებით, თქვენი იდენტიფიცირებისთვის, ელ-ფოსტაზე
					info@tvschool.ge . წერილის მიღებიდან არაუმეტეს 7 (შვიდი)
					სამუშაო დღის განმავლობაში შეწყვეტს თქვენი პერსონალური
					მონაცემების დამუშავებას.
				</p>
				<p className={termStyles.secondary}>
					როგორ შეგიძლიათ დაგვიკავშირდეთ:
				</p>
				<p>
					პერსონალურ მონაცემებთან დაკავშირებით შეგიძლიათ
					დაგვიკავშირდეთ
					<a
						href="mailto: info@tvschool.ge"
						className={termStyles.linkToMail}
					>
						info@tvschool.ge
					</a>
				</p>
			</div>
		</>
	);
};

export const Confidentiality = () => {
	const isGeo = useLocale() === "ka";
	const isDutch = useLocale() === "nl";
	return (
		<>
			{isGeo ? (
				<GeoConfidentiality />
			) : isDutch ? (
				<DutchConfidentiality />
			) : (
				<EngConfidentiality />
			)}
		</>
	);
};
